import React from 'react';
import {TextField} from '@mui/material';

const DaysToRunForInput = ({value, onChange}) => {
    return (<TextField
        label="Days"
        value={value}
        onChange={onChange}
        inputProps={{
            pattern: "[0-9]*",  // Only allow numeric input
        }}
        style={{paddingBottom: '10px', paddingRight: '10px'}}
    />);
};

export default DaysToRunForInput;