export const GapInvestParameters = {
    TODAY_VM_LOW: 12.65, //was 3.80 in current model
    TODAY_VM_HIGH: 27.68,
    GAP_DAY_CLOSE_LOW: .13,
    GAP_DAY_CLOSE_HIGH: 10.16,
    GAP_DAY_VOLUME_LOW: 1000000,
    GAP_DAY_ACCUM_LOW: -30,
    GAP_DAY_ACCUM_HIGH: -10,
    GAP_PERCENTAGE_LOW: 0, 
    GAP_PERCENTAGE_HIGH: 20.12,
    PERCENT_DOWN_FROM_ALL_TIME_HIGH_BEFORE_ALL_TIME_LOW: 88,
    PERCENT_UP_FROM_ALL_TIME_BOTTOM_LOW: 22,
    PERCENT_UP_FROM_ALL_TIME_BOTTOM_HIGH: 500,
    MIN_RATIO_LOW: 0,
    MIN_RATIO_HIGH: 5
}
