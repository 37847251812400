import React from 'react';
import {Box, Button, TextField} from '@mui/material';

const TickerSearch = ({ onSearch, currentTickerTyped, setCurrentTickerTyped }) => {

    const handleSearch = () => {
        if (currentTickerTyped) {
            onSearch(currentTickerTyped);
        }
    };

    return (
        <Box>
            <TextField
                label="Enter Ticker"
                value={currentTickerTyped}
                onChange={(e) => setCurrentTickerTyped(e.target.value.toUpperCase())}
                style={{paddingBottom: '10px', paddingRight: '10px'}}
            />
            <Button variant="contained" onClick={handleSearch}>
                Get Data
            </Button>
        </Box>
    );
};

export default TickerSearch;
