// ParametersForm.jsx
import React, {useState} from 'react';
import {Button, IconButton, TextField, Tooltip} from '@mui/material';
import Refresh from '@mui/icons-material/Replay';

const ParametersForm = ({ defaultParameters, onCalculate, currentTickerTyped, currentTickerLoaded }) => {
    const [parameters, setParameters] = useState(defaultParameters);

    const handleParameterChange = (paramName, value) => {
        setParameters((prevParameters) => ({
            ...prevParameters,
            [paramName]: value,
        }));
    };

    const handleCalculate = () => {
        onCalculate(parameters);
    };

    return (
        <div style={{paddingTop: '20px'}}>
            <Button disabled={currentTickerTyped !== currentTickerLoaded} variant="contained" onClick={handleCalculate}>
                Calculate for {currentTickerLoaded}
            </Button>
            <br />
            <div style={{display: 'flex'}}>
                <h2>Parameters</h2>
                <Tooltip title={'Revert parameters back to saved values'}>
                    <IconButton onClick={() => setParameters(defaultParameters)}>
                        <Refresh/>
                    </IconButton>
                </Tooltip>
            </div>
            <TextField
                label="Current Day VM (Low)"
                value={parameters.TODAY_VM_LOW}
                onChange={(e) => handleParameterChange('TODAY_VM_LOW', e.target.value)}
                style={{paddingBottom: '10px', paddingRight: '10px'}}
            />
            <TextField
                label="Current Day VM (High)"
                value={parameters.TODAY_VM_HIGH}
                onChange={(e) => handleParameterChange('TODAY_VM_HIGH', e.target.value)}
                style={{paddingBottom: '10px', paddingRight: '10px'}}
            />
            <br />
            <TextField
                label="Gap Day Closing Price (Low)"
                value={parameters.GAP_DAY_CLOSE_LOW}
                onChange={(e) => handleParameterChange('GAP_DAY_CLOSE_LOW', e.target.value)}
                style={{paddingBottom: '10px', paddingRight: '10px'}}
            />
            <TextField
                label="Gap Day Closing Price (High)"
                value={parameters.GAP_DAY_CLOSE_HIGH}
                onChange={(e) => handleParameterChange('GAP_DAY_CLOSE_HIGH', e.target.value)}
                style={{paddingBottom: '10px', paddingRight: '10px'}}
            />
            <br />
            <TextField
                label="Gap Day Volume (Low)"
                value={parameters.GAP_DAY_VOLUME_LOW}
                onChange={(e) => handleParameterChange('GAP_DAY_VOLUME_LOW', e.target.value)}
                style={{paddingBottom: '10px', paddingRight: '10px'}}
            />
            <br />
            <TextField
                label="Gap Day Accum (Low)"
                value={parameters.GAP_DAY_ACCUM_LOW}
                onChange={(e) => handleParameterChange('GAP_DAY_ACCUM_LOW', e.target.value)}
                style={{paddingBottom: '10px', paddingRight: '10px'}}
            />
            <TextField
                label="Gap Day Accum (High)"
                value={parameters.GAP_DAY_ACCUM_HIGH}
                onChange={(e) => handleParameterChange('GAP_DAY_ACCUM_HIGH', e.target.value)}
                style={{paddingBottom: '10px', paddingRight: '10px'}}
            />
            <br />
            <TextField
                label="Gap % (Low)"
                value={parameters.GAP_PERCENTAGE_LOW}
                onChange={(e) => handleParameterChange('GAP_PERCENTAGE_LOW', e.target.value)}
                style={{paddingBottom: '10px', paddingRight: '10px'}}
            />
            <TextField
                label="Gap % (High)"
                value={parameters.GAP_PERCENTAGE_HIGH}
                onChange={(e) => handleParameterChange('GAP_PERCENTAGE_HIGH', e.target.value)}
                style={{paddingBottom: '10px', paddingRight: '10px'}}
            />
            <br />
            <TextField
                label="% Down From ATH Before ATL"
                value={parameters.PERCENT_DOWN_FROM_ALL_TIME_HIGH_BEFORE_ALL_TIME_LOW}
                onChange={(e) => handleParameterChange('PERCENT_DOWN_FROM_ALL_TIME_HIGH_BEFORE_ALL_TIME_LOW', e.target.value)}
                style={{paddingBottom: '10px', paddingRight: '10px'}}
            />
            <br />
            <TextField
                label="% Up From All Time Bottom (Low)"
                value={parameters.PERCENT_UP_FROM_ALL_TIME_BOTTOM_LOW}
                onChange={(e) => handleParameterChange('PERCENT_UP_FROM_ALL_TIME_BOTTOM_LOW', e.target.value)}
                style={{paddingBottom: '10px', paddingRight: '10px'}}
            />
            <TextField
                label="% Up From All Time Bottom (High)"
                value={parameters.PERCENT_UP_FROM_ALL_TIME_BOTTOM_HIGH}
                onChange={(e) => handleParameterChange('PERCENT_UP_FROM_ALL_TIME_BOTTOM_HIGH', e.target.value)}
                style={{paddingBottom: '10px', paddingRight: '10px'}}
            />
            <br />
            <TextField
                label="Min Ratio (Low)"
                value={parameters.MIN_RATIO_LOW}
                onChange={(e) => handleParameterChange('MIN_RATIO_LOW', e.target.value)}
                style={{paddingBottom: '10px', paddingRight: '10px'}}
            />
            <TextField
                label="Min Ratio (High)"
                value={parameters.MIN_RATIO_HIGH}
                onChange={(e) => handleParameterChange('MIN_RATIO_HIGH', e.target.value)}
                style={{paddingBottom: '10px', paddingRight: '10px'}}
            />
            <br />
        </div>
    );
};

export default ParametersForm;
