import React, {useState} from 'react';
import {Button, TextField} from '@mui/material';
import {keys} from "./secrets/keys";

const LoginPage = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = () => {

        const predefinedUsername = keys.USERNAME;
        const predefinedPassword = keys.PASSWORD;

        if (username === predefinedUsername && password === predefinedPassword) {
            onLogin();
        } else {
            alert('Invalid username or password');
        }
    };

    return (
        <div style={{paddingLeft: '20px'}}>
            <h1>Login</h1>
            <TextField
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={{paddingBottom: '10px'}}
            />
            <br />
            <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{paddingBottom: '10px'}}
            />
            <br />
            <Button variant="contained" onClick={handleLogin}>
                Login
            </Button>
        </div>
    );
};

export default LoginPage;