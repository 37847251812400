
export const runGapInvestForTheLastGivenDays = (defaultParams, days, opens, highs, lows, closings, volumes, dates) => {
  const daysToRunFor = days;
  const totalTradingDays = opens.length - 1;
  const daysNeeded = parseInt(daysToRunFor) + 115;
  const indicesOfGapInvestSignals = [];

  if(totalTradingDays < daysToRunFor) {return 'Cannot run for ' + daysToRunFor + ' days because alphavantage only has data for the last ' + totalTradingDays + ' days'}
  if(totalTradingDays < daysNeeded) {return 'Alphavantage has data for the last ' + totalTradingDays + ' days, Gap Invest needs at least 115 trading days in the past to perform the calculation'}

  for(var i = days - 1; i >= 0; i--) {
    if(testsPassForCurrentDay(defaultParams, i, opens, highs, lows, closings, volumes)) {
      const indexOfGapInvestSignal = testsPassForLastFiftyDays(defaultParams, i, opens, highs, lows, closings, volumes);
      if(indexOfGapInvestSignal > -1) {indicesOfGapInvestSignals.push(indexOfGapInvestSignal)}
    }
  }
  let gapInvestDates = ''
  indicesOfGapInvestSignals.slice().reverse().forEach((index) => {
    gapInvestDates += `${dates[index]}, `
  })
  return gapInvestDates !== '' ? gapInvestDates : 'no signals';
}

const testsPassForCurrentDay = (defaultParams, currentDay, opens, highs, lows, closings, volumes) => {
  const curDayVolumeMultiple = getVolumeMultiple(currentDay, volumes);
  const curDayHigh = highs[currentDay];
  const curDayClose = closings[currentDay];
  const curDayOpen = opens[currentDay];
  const yesterdayClose = closings[currentDay + 1];

  return (curDayHigh + Math.min(curDayOpen, yesterdayClose)) / 2.0 < curDayClose &&
      curDayVolumeMultiple > defaultParams.TODAY_VM_LOW && curDayVolumeMultiple < defaultParams.TODAY_VM_HIGH &&
      curDayClose > yesterdayClose;
}

const testsPassForLastFiftyDays = (defaultParams, currentDay, opens, highs, lows, closings, volumes) => {

  for(var i = currentDay; i <= currentDay + 50; i++) {
    const curDayClose = closings[i];
    const curDayVol = volumes[i];
    const curDayLow = lows[i];
    const prevDayClose = closings[i + 1];
    const gapPercentage = ((curDayLow / prevDayClose) - 1) * 100.0;
    const curDayAccum = getAccumulationForGivenDay(i, opens, highs, lows, closings, volumes);

    if(curDayClose <= defaultParams.GAP_DAY_CLOSE_HIGH &&
        curDayClose >= defaultParams.GAP_DAY_CLOSE_LOW &&
        curDayVol >= defaultParams.GAP_DAY_VOLUME_LOW &&
        curDayAccum >= defaultParams.GAP_DAY_ACCUM_LOW &&
        curDayAccum <= defaultParams.GAP_DAY_ACCUM_HIGH &&
        gapPercentage >= defaultParams.GAP_PERCENTAGE_LOW &&
        gapPercentage <= defaultParams.GAP_PERCENTAGE_HIGH &&
        curDayLow - .01 > prevDayClose &&
        testsPassForPercentDownAndPercentUp(defaultParams, curDayClose, lows, highs) &&
        testsPassForMinRatio(defaultParams, currentDay, i, lows, closings)) {
      return currentDay;
    }
  }

  return -1;
}

const testsPassForPercentDownAndPercentUp = (defaultParams, curDayClose, lows, highs) => {
  const allTimeLow = Math.min(...lows);
  const allTimeLowIndex = lows.indexOf(allTimeLow);
  const highsBeforeAllTimeLow = highs.slice(allTimeLowIndex + 1)
  const allTimeHighBeforeAllTimeLow = Math.max(...highsBeforeAllTimeLow)
  const percentDownFromAllTimeHighBeforeAllTimeLow = (1 - (curDayClose / allTimeHighBeforeAllTimeLow)) * 100.0;
  const percentUpFromAllTimeLow = ((curDayClose / allTimeLow) - 1) * 100.0;
  return percentDownFromAllTimeHighBeforeAllTimeLow >= defaultParams.PERCENT_DOWN_FROM_ALL_TIME_HIGH_BEFORE_ALL_TIME_LOW &&
      percentUpFromAllTimeLow >= defaultParams.PERCENT_UP_FROM_ALL_TIME_BOTTOM_LOW &&
      percentUpFromAllTimeLow <= defaultParams.PERCENT_UP_FROM_ALL_TIME_BOTTOM_HIGH;
}

const testsPassForMinRatio = (defaultParams, currentDay, i, lows, closings) => {
  const prevDayClose = closings[i + 1];
  const mins = lows.slice(currentDay, i + 1);
  const minimumLow = Math.min(...mins);
  const minRatio = minimumLow / prevDayClose;

  return minRatio >= defaultParams.MIN_RATIO_LOW && minRatio <= defaultParams.MIN_RATIO_HIGH;
}

export const getAccumulationForGivenDay = (
    day,
    opens,
    highs,
    lows,
    closings,
    volumes
) => {
  let upperVolumeCount = 0;
  let lowerVolumeCount = 0;
  let totalDays = opens.length;
  closings.push(closings[totalDays - 1]);

  for (var i = day; i < totalDays; i++) {
    // - 1 so we can always get the previous day
    let curDayHigh = highs[i];
    let curDayClose = closings[i];
    let curDayVol = volumes[i];
    let curDayLow = lows[i];
    let prevDayClose = closings[i + 1];

    if (curDayLow > prevDayClose) {
      if ((prevDayClose + curDayHigh) / 2.0 < curDayClose) {
        upperVolumeCount += curDayVol;
      } else if ((prevDayClose + curDayHigh) / 2.0 > curDayClose) {
        lowerVolumeCount += curDayVol;
      }
    } else {
      if ((curDayLow + curDayHigh) / 2.0 < curDayClose) {
        upperVolumeCount += curDayVol;
      } else if ((curDayLow + curDayHigh) / 2.0 > curDayClose) {
        lowerVolumeCount += curDayVol;
      }
    }
  }

  return (upperVolumeCount / lowerVolumeCount - 1) * 100;
};
export const getVolumeMultiple = (currentDay, volumes) => {
  const currentDayVolume = volumes[currentDay];
  var volumeSum = 0;
  for (var i = currentDay + 1; i <= currentDay + 15; i++) {
    volumeSum += volumes[i];
  }
  return currentDayVolume / (volumeSum / 15.0);
};