import React, {useState} from 'react';
import TickerSearch from './tickerSearch';
import DateSpecifier from './dateSpecifier';
import fetchStockData from './hcc-dao';
import Results from './results';
import {runGapInvestForTheLastGivenDays} from "./calculations";
import DaysToRunForInput from "./daysToRunForInput";
import LoginPage from "./LoginPage";
import './App.css';
import ParametersForm from "./ParametersForm";
import {GapInvestParameters} from "./Parameters/GapInvestParameters";
import {keys} from "./secrets/keys";

const ALPHAVANTAGE_API_KEY = keys.ALPHAVANTAGE_API_KEY; // Replace with your actual API key

function App() {
  const [stockData, setStockData] = useState('');
  const [calculationResults, setCalculationResults] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [currentTickerLoaded, setCurrentTickerLoaded] = useState('');
  const [currentTickerTyped, setCurrentTickerTyped] = useState('');
  const [daysToRunFor, setDaysToRunFor] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Add isLoggedIn state
  const defaultParams = GapInvestParameters;

  const handleLogin = () => {
    setIsLoggedIn(true);
  };
  const handleSearch = async (ticker) => {
    try {
      const data = await fetchStockData(ticker, ALPHAVANTAGE_API_KEY);
      if(data["Error Message"])
        alert('invalid api call: either cannot find stock or call has failed')
      setStockData(data);
      setDaysToRunFor((Object.keys(data["Time Series (Daily)"]).length - 120).toString())
      setCurrentTickerLoaded(ticker);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const handleCalculate = (defaultParams) => {
    setCalculationResults('')
    if (!selectedDate) {
      console.error('Please select a date before calculating');
      return;
    }
    if (!stockData || !stockData['Time Series (Daily)']) {
      console.error('Stock data is missing or in an unexpected format');
      return;
    }

    const timeSeries = stockData['Time Series (Daily)'];
    const dates = [];
    const opens = [];
    const highs = [];
    const lows = [];
    const closes = [];
    const volumes = [];

    let foundSelectedDate = false;

    for (const date in timeSeries) {
      if (timeSeries.hasOwnProperty(date)) {
        if (!foundSelectedDate) {
          if (date === selectedDate) {
            foundSelectedDate = true;
          } else {
            continue;
          }
        }

        const data = timeSeries[date];
        dates.push(date);
        opens.push(+data['1. open']);
        highs.push(+data['2. high']);
        lows.push(+data['3. low']);
        closes.push(+data['4. close']);
        volumes.push(parseInt(data['6. volume'], 10));
      }
    }

    const results = runGapInvestForTheLastGivenDays(defaultParams, daysToRunFor, opens, highs, lows, closes, volumes, dates);
    setCalculationResults(results);
  };

  if (!isLoggedIn) {
    return <LoginPage onLogin={handleLogin} />;
  }

  return (
      <div className="App" style={{paddingLeft: '20px'}}>
        <div className="app-wrapper"> {/* Add this wrapper */}
        <h1>HCC Analysis Dashboard</h1>
        <TickerSearch onSearch={handleSearch} currentTickerTyped={currentTickerTyped} setCurrentTickerTyped={setCurrentTickerTyped}/>
        <DateSpecifier onDateSelect={handleDateSelect} />
        <DaysToRunForInput value={daysToRunFor} onChange={(e) => setDaysToRunFor(e.target.value)} />
          {/*<Button variant="contained" onClick={handleCalculate}>*/}
          {/*  Calculate for {currentTicker}*/}
          {/*</Button>*/}
          <Results calculationResults={calculationResults}/>
          <ParametersForm defaultParameters={defaultParams} onCalculate={handleCalculate} currentTickerTyped={currentTickerTyped} currentTickerLoaded={currentTickerLoaded} />
        </div>
      </div>
  );
}

export default App;